import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Main from './Main/Main';
import Plaintext from './Plaintext';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route
          path="/bandhistory"
          element={<Plaintext textKey="band-history" />}
        />
        <Route path="/imprint" element={<Plaintext textKey="imprint" />} />
        <Route
          path="/privacy-policy"
          element={<Plaintext textKey="privacy-policy" />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
